html,
body {
  padding: 0;
  margin: 0;
  font-family: Ten Ten Rounded;
  background-color: #f00;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Ten Ten Rounded';
  src: url('/public/fonts/TenTenRounded-Medium.eot');
  src: url('/public/fonts/TenTenRounded-Medium.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/TenTenRounded-Medium.woff2') format('woff2'),
      url('/public/fonts/TenTenRounded-Medium.woff') format('woff'),
      url('/public/fonts/TenTenRounded-Medium.ttf') format('truetype'),
      url('/public/fonts/TenTenRounded-Medium.svg#TenTenRounded-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
