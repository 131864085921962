.Home-container {
  padding: 0 2rem;
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px;
}

.Home-main-container {
  min-height: 100%;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Home-footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  display: flex;
  flex: 1;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
}

.Home-footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0.015;
  color: white
}

.Home-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 375px) {
  .Home-logo > img {
    transform: scale(0.8);
  }
}
